function backToTop() {
    topButton.classList.add('hide');

    window.scroll({
        top: 0,
        left: 0,
    });
}

const topButton = document.getElementById('topButton');

function showBackToTopButton() {
    if (window.scrollY > 100) {
        topButton.classList.remove('hide');
    } else {
        topButton.classList.add('hide');
    }
}

topButton.addEventListener('click', backToTop);
window.addEventListener('scroll', showBackToTopButton);